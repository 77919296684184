import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './views/home/home.component';
import { ChatComponent } from './views/chat/chat.component';
import { FaturaFormComponent } from './views/fatura/fatura-form/fatura-form.component';
import { AuthGuard } from './services/guard';
import { FormComponent } from './form/form.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { GuideComponent } from './views/guide/guide.component';
import { HelpComponent } from './views/help/help.component';
import { TrainsystemComponent } from './views/trainsystem/trainsystem.component';
import { ReportsComponent } from './views/reports/reports.component';
import { AibiComponent } from './views/aibi/aibi.component';
import { DocumentsComponent } from './views/documents/documents.component';
import { LuaxappsComponent } from './views/luaxapps/luaxapps.component';
import { MyAccountComponent } from './views/my-account/my-account.component';
import { NotificationsComponent } from './views/notifications/notifications.component';
import { AlertasComponent } from './views/alertas/alertas.component';
import { DynamicPageComponent } from './views/dynamic-page/dynamic-page.component';
import { AnalyticsComponent } from './views/analytics/analytics.component';
import { LuaXlsFinancialComponent } from './lua-xls-financial/lua-xls-financial.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { WelcomeToLuaComponent } from './welcome-to-lua/welcome-to-lua.component';
import { LuaEntertainComponent } from './views/lua-entertain/lua-entertain.component';
//import { PerformanceComponent } from './components/performance/performance.component';

const routes: Routes = [
  // { path: '', component: LandpageComponent },
  {path: '', component:WelcomeToLuaComponent},
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard] },
  { path: 'performance', component: FormComponent, canActivate: [AuthGuard] },
  { path: 'auth', loadChildren: () => import('./views/auth/auth.module').then((m) => m.AuthModule) },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'guide', component: GuideComponent },
  { path: 'help', component: HelpComponent, canActivate: [AuthGuard] },
  { path: 'trainsystem', component: TrainsystemComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'aibi', component: AibiComponent, canActivate: [AuthGuard] },
  { path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard] },
  { path: 'luaxapps', component: LuaxappsComponent, canActivate: [AuthGuard] },
  { path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard] },
  { path: 'ai-inbox', component: AibiComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'alertas', component: AlertasComponent, canActivate: [AuthGuard] },
  { path: 'apps/:appName/:pageName/:version', component: DynamicPageComponent, canActivate: [AuthGuard] },
  { path: 'analitics', component: AnalyticsComponent, canActivate: [AuthGuard] },
  { path: 'lua-xls-financial', component: LuaXlsFinancialComponent, canActivate: [AuthGuard] },
  { path: 'monitoring', component: MonitoringComponent, canActivate: [AuthGuard] },
  { path: 'lua-entertain', component:LuaEntertainComponent, canActivate: [AuthGuard] },
 




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
