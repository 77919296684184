<app-user-layout>

    <div class="">   
        <h3 class="text-2xl font-bold text-left "> Documentos </h3>
    </div>

    <div class="mt-4">  

        <div class="w-full">
            <div class="tab-header flex border-b border-gray-300">
                <div *ngIf="currentDocOpen === null" 
                     class="tab-header-item cursor-pointer py-2 px-4 bg-[#00527E] border border-gray-300 rounded-tl rounded-tr text-white">
                    Lista
                </div>
                <div *ngIf="currentDocOpen !== null" 
                     class="tab-header-item cursor-pointer py-2 px-4 bg-[#00527E] border border-gray-300 rounded-tl rounded-tr text-white">
                    <p>{{ currentDocOpen['realname'] }}</p>
                </div>
            </div>
            

            <div class=" p-2 tab-content md:p-10 border border-gray-300 rounded-b">

                <div *ngIf="currentDocOpen===null">
                    
                    <div class="flex justify-between items-center mb-0 ">
                        <div>
                            <h3 class="font-bold text-sm font-poppins title-padding">
                                Mostrando {{objectList.length}} items
                                <!-- Mostrando 10 de 20 -->
                            </h3>
                        </div>
                        <div style="position: relative;">
                            <input type="text" placeholder="Pesquisar..." class="border border-gray-300 px-5 py-1 rounded-full" 
                                style="padding-left: 40px;" />
                            <img src="assets/img/icon/search.svg" alt=""  
                                style="position: absolute; top: 50%; left: 10px; transform: translateY(-50%);" 
                            />
                        </div>
                    </div>

                    <div class="flex justify-between items-center mb-4">
                        <div class="flex space-x-3">
                            <button class="border p-2 rounded-full bg-[#2571a1] text-white border-[#5BACAD] flex items-center"
                                (click)="onRefreshDocList()">
                                Actualizar
                            </button>
                      </div>
                        <div class="flex space-x-5">
                    </div>
                    </div>
                </div>
                <div class="col-span-1 md:col-span-2"
                    *ngIf="is_loading==true">
                    <div class="flex items-center rounded-xl rounded-tl-none p-[1rem] w-[7rem] ">
                        <div role="status">
                            <svg aria-hidden="true" class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor" />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill" />
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>


                <div *ngIf="is_loading==false && objectList.length>0" >              
                    <div *ngIf="currentDocOpen===null" >                 
                        <app-fatura [objectList]="objectList" [onSelect]="openFileItem" class="md:pb-8">
                        </app-fatura>
                    </div>

                    <div *ngIf="currentDocOpen!==null">
                        <div class="mt-3 mb-3">              
                            <app-alert type="info" 
                                message="Carregando detalhes"
                                *ngIf="loadCache[currentDocOpen.fileid].Status=='LOADING'">
                            </app-alert>
                            <app-alert type="error" 
                                message="Nao foi possivel carregar os detalhes"
                                *ngIf="loadCache[currentDocOpen.fileid].Status=='FAILED'">
                            </app-alert>
                        </div>

                        <div *ngIf="loadCache[currentDocOpen.fileid].Status=='DETAILS_LOAD'">
                            <div class=" mb-5 p-2">
                                <!-- <h3>DOCUMENTO</h3> -->
                                <div class="grid grid-cols-2">
                                    <div class="grid-col "
                                        >
                                        <p class=" -mt-10 mb-10" (click)="closeCurrentFile()">
                                            <span class="cursor-pointer text-sm p-2 rounded-xl font-bold text-white m-2 border border-[#2571a1] bg-[#2571a1]"> 
                                                Voltar
                                            </span>
                                        </p>
                                        <div class="header">
                                            <div class="invoice-info">
                                                <h2 class="text-left opacity-70" style="font-size:small">
                                                    Documentos / {{ currentDocOpen['fileid'] }} 
                                                </h2>
                                                <h1 class="font-semibold text-2xl pb-1" >
                                                    {{currentDocOpen['realname']}}
                                                </h1>
                                               
                                            </div>

                                        
                                        </div>
                                    </div>

                                    <div class="grid-col text-right">
                                        <div class="grid grid-cols-2">
                                            <div class="grid-col">
                                                <p class="text-right opacity-70"
                                                    style="font-size:x-small;">
                                                    &Iacute;ndice de <br/>Complexidade
                                                </p>
                                                <h1 class=" pb-1">
                                                    <span class="font-semibold text-6xl">             
                                                    {{ loadCache[currentDocOpen.fileid].Details.info_group_response.doc_cplx.info.cplx_index }}
                                                    </span>
                                                </h1>
                                            </div>

                                            <div class="grid-col">
                                                <p class="text-right opacity-70"
                                                    style="font-size:x-small;">
                                                    Estimativa de <br/>itera&ccedil;&otilde;es
                                                </p>
                                                <h1 class=" pb-1">
                                                    <span class="font-semibold text-6xl opacity-70">                  
                                                        {{ loadCache[currentDocOpen.fileid].Details.info_group_response.doc_cplx.info.est_iterations }}
                                                    </span>
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-5"></div>

                                <div class="grid grid-cols-1 ">
                                    <div class="grid-col "
                                        *ngIf="currentDocOpen.fileid+'#REPORTS' as keyCache">
                                        <hr/>

                                        <h3 class="text-2xl font-extrabold text-left mt-5 mb-8 pl-4">
                                            Relatórios
                                        </h3>
                                        <p class="mt-2 mb-2 pl-3">
                                            <span class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2"
                                                  (click)="queryDocumentObject(currentDocOpen.fileid, 'REPORTS')"
                                                  style="border: 1px solid rgb(0, 82, 126);"> 
                                                Actualizar
                                            </span>
                                            <span class="cursor-pointer text-sm p-2 bg-white rounded-xl font-bold m-2 border border-[#00527E] text-[#00527E]"
                                                  *ngIf="!show_group_docs"
                                                  (click)="toggleGroupDocs()"> 
                                                Ver Tipos/Grupos
                                            </span>
                                            <span class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold m-2 mb-5"
                                                  *ngIf="show_group_docs"
                                                  style="border: 1px solid rgb(120, 7, 209);color:#00527E"
                                                  (click)="toggleGroupDocs()"> 
                                                Esc. Tipos/Grupos
                                            </span>
                                        </p>

                                        <div class="grid grid-cols-2 mt-8 gap-4 p-2.5 ml-4.5 mr-5 max-h-64 overflow-hidden overflow-y-scroll border border-gray-200" *ngIf="show_group_docs">
                                            <div class="col-span-1 h-full" *ngFor="let groupDoc of loadCache[currentDocOpen.fileid].Details.info_group_response.groups_doc">
                                              <div *ngIf="currentDocOpen.fileid as FileId_" class="mb-5 flex flex-col h-full">
                                                <div *ngIf="objectCache[FileId_+'#'+groupDoc.group_name]">
                                                  <app-alert type="info"
                                                    message="gerando relatorio"
                                                    *ngIf="objectCache[FileId_+'#'+groupDoc.group_name].Status=='LOADING'">
                                                  </app-alert>
                                                  <app-alert type="error"
                                                    message="Nao foi possivel gerar o relatorio"
                                                    *ngIf="objectCache[FileId_+'#'+groupDoc.group_name].Status=='FAILED'">
                                                  </app-alert>
                                                </div>
                                          
                                                <h4 class="">{{ (groupDoc.label || groupDoc.group_name).toUpperCase() }}</h4>
                                                <p class="text-sm opacity-70 overflow-hidden" style="font-size: x-small; max-width: 100%;">
                                                  {{ groupDoc.descr }}
                                                </p>
                                                <p class="mt-5 mb-5">
                                                  <span class="cursor-pointer text-small p-2 bg-white rounded-xl font-bold text-[#00527E] m-2 border border-[#00527E]"
                                                    (click)="executeGroup(FileId_, groupDoc)">
                                                    Executar
                                                  </span>
                                                </p>
                                                <hr class="mt-auto" />
                                              </div>
                                            </div>
                                          </div>
                                          

                                        <div *ngIf="objectCache[keyCache]"
                                            class="mt-5 pl-3 pr-3">
                                            <app-alert type="info" 
                                                message="Carregando relatorios"
                                                *ngIf="objectCache[keyCache].Status=='LOADING'">
                                            </app-alert>

                                            <app-alert type="error" 
                                                message="Nao foi possivel carregar os relatorios"
                                                *ngIf="objectCache[keyCache].Status=='FAILED'">
                                            </app-alert>

                                            <div class="grid-col" 
                                                *ngIf="objectCache[keyCache].info && objectCache[keyCache].info.length==0">
                                                <p class="opacity-70 m-0">Nenhum relat&oacute;rio encontrado</p>
                                            </div>

                                            <div class="mt-5 pl-3 pr-3"
                                                *ngIf="objectCache[keyCache].Status=='LOADED' && objectCache[keyCache].info">

                                                <div class="grid-col" 
                                                    *ngIf="objectCache[keyCache].info.length>0">
                                                    <p class="opacity-70 m-0">
                                                        {{ objectCache[keyCache].info.length}} 
                                                        relatórios encontrados.
                                                    </p>
                                                </div>

                                                <div class="grid grid-cols-2">
                                                    <div class="grid-col "
                                                        *ngFor="let blockObj of objectCache[keyCache].info">

                                                        <div class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div class="flex flex-col md:flex-row justify-between items-center">
                                                                    <!-- Nome -->
                                                                    <span class="text-sm text-[#1422a9] font-medium" style="padding-left: 9px;">
                                                                        {{ blockObj.name.toUpperCase() }}
                                                                    </span>
                                                                    
                                                                    <!-- Espaço reservado para o texto branco (não visível na tela) -->
                                                                    <span class="text-sm text-white" style="padding-right: 9px; font-size: xx-small;"></span>
                                                            
                                                                    <!-- Botões -->
                                                                    <div class="flex flex-col md:flex-row items-center">
                                                                        <span class="cursor-pointer pr-2 text-[small] bg-[#fff] rounded-xl font-bold text-[#00527E] mb-2 md:mb-0" style="font-size: x-small;">
                                                                            <img src="assets/img/icon/thumbsUp.svg" alt="Like this report" class="w-50" style="display: inline !important;" />
                                                                        </span>
                                                                        <span class="cursor-pointer pl-2 text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E]" style="font-size: x-small;">
                                                                            Gerar+
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            

                                                            <div class="panel-content"
                                                                style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;">
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col" *ngIf="blockObj.report">
                                                                        <app-fatura-details 
                                                                            [report]="blockObj.report">
                                                                        </app-fatura-details>
                                                                    </div>
                                                                    <div class="grid-col" *ngIf="!blockObj.report">
                                                                        <p>Sem Relatorio para {{ blockObj.name.toUpperCase() }} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="mt-5"></div>

                                <div class="grid grid-cols-1 mt-5" 
                                    *ngIf="currentDocOpen">
                                    <div class="grid-col">
                                        <app-generative-ai-iterator 
                                            [doc]="currentDocOpen"
                                            [assistantName]="'genai/BigAssistant'">
                                        </app-generative-ai-iterator>
                                    </div>

                                    <div class="grid-col mt-5">
                                        <h3 class="text-2xl font-extrabold text-left mt-5 mb-0">
                                            Regras &amp; Parametros
                                        </h3>
                                        <hr/>

                                        <h3 class="text-2xl font-extrabold text-left mt-5 mb-0">
                                            Feedback
                                        </h3>
                                        <hr/>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 mt-5">
                                </div>
                            </div>
                        </div>
                        <div class="mb-5"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</app-user-layout>